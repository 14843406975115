<template>
    <form id="frm_action">
        <div class="p-fluid">
            <div class="p-col-12 p-lg-12 view-action">
                <div class="card card-w-title">
                    <Panel>
                        <template slot="header">
                            <h2>Thông tin cá nhân</h2>
                        </template>
                        <div class="p-formgrid">
                            <div class="p-card-field p-grid">
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Khách hàng <span class="span-cus">*</span></label>
                                    <div class="p-col">
                                        <v-select :clearable="false" title="Khách hàng" id= "a1" :filterable="false" placeholder="Chọn khách hàng" v-model="selUser" :options="listUser" label="name" @search="fetchOptionsUser" class="p-col-width p-inputtext p-component p-filled">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                Không có dữ liệu
                                            </span>
                                        </v-select>
                                        <input class="input_tmp_validator" id="user_id">
                                    </div>
                                </div>
                                
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Nhân viên phụ trách</label>
                                    <div class="p-col">
                                        <v-select :clearable="false" title="Nhân viên phụ trách" id= "a1" :filterable="false" placeholder="Chọn Nhân viên" v-model="selStaff" :options="listStaff" label="name" @search="fetchOptionsStaff" class="p-col-width p-inputtext p-component p-filled">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                Không có dữ liệu
                                            </span>
                                        </v-select>
                                        <input class="input_tmp_validator" id="user_id">
                                    </div>
                                </div>
                                
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Email <span class="span-cus">*</span></label>
                                    <div class="p-col">
                                        <!-- <label class="p-inputtext p-component p-filled disabled">{{email}}</label> -->
                                        <InputText v-model="email" type="text" disabled="disabled" id="email" placeholder="Email"/>
                                    </div>
                                </div>
                                
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Họ <span class="span-cus">*</span></label>
                                    <div class="p-col">
                                        <!-- <label class="p-inputtext p-component p-filled disabled">{{ first_name }} <span class="span-cus">*</span></label> -->
                                        <InputText v-model="first_name" type="text" id="first_name" placeholder="Họ"/>
                                    </div>
                                </div>
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Tên <span class="span-cus">*</span></label>
                                    <div class="p-col">
                                        <!-- <label class="p-inputtext p-component p-filled disabled">{{ last_name }}</label> -->
                                        <InputText v-model="last_name" type="text" id="last_name" placeholder="Tên"/>
                                    </div>
                                </div>
                                
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Số điện thoại <span class="span-cus">*</span></label>
                                    <div class="p-col">
                                        <!-- <label class="p-inputtext p-component p-filled disabled">{{ phone }}</label> -->
                                        <InputText v-model="phone" type="text" id="phone" placeholder="Số điện thoại"/>
                                    </div>
                                </div>
                                
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Ngày sinh <span class="span-cus">*</span></label>
                                    <div class="p-col">
                                        <!-- <label class="p-inputtext p-component p-filled disabled">{{ date }}</label> -->
                                        <span class="p-float-label">
                                            <Calendar
                                                :showOnFocus="true"
                                                :monthNavigator="true"
                                                :yearNavigator="true"
                                                :yearRange="DateRangeBirthday"
                                                dateFormat="dd/mm/yy"
                                                :maxDate="new Date()"
                                                v-model="full_birthday"
                                                selectionMode="single"
                                                :manualInput="false"
                                                :showIcon="true"
                                                :showClear="true"
                                                :showButtonBar="true"
                                                inputId="birth_date" 
                                            />
                                            <label for="birth_date">Ngày sinh</label>
                                        </span>
                                        <input class="input_tmp_validator" id="full_birthday">
                                    </div>
                                </div>
                                
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Giới tính <span class="span-cus">*</span></label>
                                    <div class="p-col">
                                        <Dropdown v-model="setGender" :options="listGender" optionLabel="label" placeholder="Giới tính"/>
                                        <input class="input_tmp_validator" id="gender">
                                    </div>
                                </div>
                                
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Mã khách hàng</label>
                                    <div class="p-col">
                                        <!-- <label class="p-inputtext p-component p-filled disabled">{{ phone }}</label> -->
                                        <InputText v-model="patient_code" type="text" id="patient_code" placeholder="Mã khách hàng"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Panel>
                    <Panel>
                        <template slot="header">
                            <h2>Thông tin lịch hẹn</h2>
                        </template>
                        <div class="p-formgrid">
                            <div class="p-card-field p-grid">
                            
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Chi nhánh <span class="span-cus">*</span></label>
                                    <div class="p-col">
                                        <v-select :clearable="false" title="Chi nhánh" id= "a1" :filterable="false" placeholder="Chọn chi nhánh" v-model="selBranch" :options="listBranch" label="name" @search="fetchOptionsBranch" class="p-col-width p-inputtext p-component p-filled">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                Không có dữ liệu
                                            </span>
                                        </v-select>
                                        <input class="input_tmp_validator" id="branch_id">
                                    </div>
                                </div>
                                
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Trạng thái <span class="span-cus">*</span></label>
                                    <div class="p-col">
                                        <Dropdown :disabled="[3, 4].includes(status_old)" v-model="selStatus" :options="listStatus" optionLabel="label" placeholder="Trạng thái"/>
                                        <input class="input_tmp_validator" id="status">
                                    </div>
                                </div>
                            </div>
                            <div class="p-card-field p-grid">
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Ngày hẹn <span class="span-cus">*</span></label>
                                    <div class="p-col">
                                        <!-- <label class="p-inputtext p-component p-filled disabled">{{ date }}</label> -->
                                        <span class="p-float-label">
                                            <Calendar
                                                :showOnFocus="true"
                                                :monthNavigator="true"
                                                :yearNavigator="true"
                                                :yearRange="DateRange"
                                                dateFormat="dd/mm/yy"
                                                v-model="date_booking"
                                                selectionMode="single"
                                                :manualInput="false"
                                                :showIcon="true"
                                                :showClear="true"
                                                :showButtonBar="true"
                                                inputId="date_booking_id"
                                            />
                                            <label for="date_booking_id">Ngày hẹn</label>
                                        </span>
                                        <input class="input_tmp_validator" id="date_booking">
                                    </div>
                                </div>
                                
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Giờ hẹn <span class="span-cus">*</span></label>
                                    <div class="p-col">
                                        <v-select :clearable="false" title="Giờ hẹn" id= "a1" :filterable="false" placeholder="Chọn giờ hẹn" v-model="selTime" :options="listTime" label="name" @search="fetchOptionsTime" class="p-col-width p-inputtext p-component p-filled">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                Không có dữ liệu
                                            </span>
                                        </v-select>
                                        <input class="input_tmp_validator" id="time_id">
                                    </div>
                                </div>
                                
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Có đi cùng bạn không?</label>
                                    <div class="p-col">
                                    <!-- <label class="p-inputtext p-component p-filled disabled">{{ go_with_name }}</label> -->
                                        <div class="flex flex-wrap gap-3">
                                            <span class="flex align-items-center" style="padding: 0 2em 0 0;">
                                                <RadioButton v-model="go_with" inputId="go_with_yes" name="go_with" :value="true" />
                                                <label style="margin-bottom: 0" for="go_with_yes" class="ml-2">Có</label>
                                            </span>
                                            <span class="flex align-items-center" style="padding: 0 2em 0 0;">
                                                <RadioButton v-model="go_with" inputId="go_with_no" name="go_with" :value="false" />
                                                <label style="margin-bottom: 0" for="go_with_no" class="ml-2">Không</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Panel>
                    <Panel>
                        <template slot="header">
                            <h2>Thông tin dịch vụ</h2>
                        </template>
                        <div class="p-formgrid">
                            <div class="p-card-field p-grid">
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Loại dịch vụ <span class="span-cus">*</span></label>
                                    <div class="p-col">
                                        <!-- <label class="p-inputtext p-component p-filled disabled">{{ type_service_name }}</label> -->
                                        <v-select :clearable="false" title="Loại dịch vụ" id= "a1" :filterable="false" placeholder="Chọn loại dịch vụ" v-model="selTypeService" :options="listTypeService" label="name" @search="fetchOptionsTypeService" class="p-col-width p-inputtext p-component p-filled">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                Không có dữ liệu
                                            </span>
                                        </v-select>
                                        <input class="input_tmp_validator" id="typeservice_id">
                                    </div>
                                </div>
                                
                                <div class="p-field p-col-6">
                                    <label class="p-col-fixed txt-left">Dịch vụ <span class="span-cus">*</span></label>
                                    <div class="p-col">
                                        <v-select :clearable="false" multiple title="Dịch vụ" id= "a1" :filterable="false" placeholder="Chọn dịch vụ" v-model="selService" :options="listService" label="name" @search="fetchOptionsService" class="p-col-width p-inputtext p-component p-filled">
                                            <span slot="no-options" @click="$refs.select.open = false">
                                                Không có dữ liệu
                                            </span>
                                        </v-select>
                                        <input class="input_tmp_validator" id="service_id">
                                    </div>
                                </div>
                                
                                <div class="p-field p-col-12">
                                    <div class="p-col">
                                    <DataTable v-if="!reexamine" :value="listQuestion" :paginator="false" class="p-datatable-category" :rows="50"
                                        dataKey="id" :filters="filters" style="width: 100%">
                                        <template #empty>
                                            Không có dữ liệu.
                                        </template>
                                        <Column header="STT" headerStyle="width: 5%;text-align:center;" bodyStyle="width: 5%; text-align:center;">
                                            <template #body="{ data, frozenRow, index }">
                                                {{ ( index + 1) }}
                                            </template>
                                        </Column>
                                        <Column field="question_title" header="Câu hỏi" headerStyle="width: 75%;text-align:center;">
                                            <template #body="slotProps">
                                                {{slotProps.data.name}}
                                            </template>
                                        </Column>
                                        <Column field="answer" header="Trả lời" headerStyle="width: 20%;text-align:center;" bodyStyle="width: 20%; text-align:center;">
                                            <template #body="slotProps">
                                                <div class="flex flex-wrap gap-3">
                                                    <span class="flex align-items-center" style="padding: 0 2em 0 0;">
                                                        <RadioButton v-model="slotProps.data.answer" inputId="answer_yes" name="answer" :value="true" />
                                                        <label style="margin-bottom: 0" for="answer_yes" class="ml-2">Có</label>
                                                    </span>
                                                    <span class="flex align-items-center" style="padding: 0 2em 0 0;">
                                                        <RadioButton v-model="slotProps.data.answer" inputId="answer_no" name="answer" :value="false" />
                                                        <label style="margin-bottom: 0" for="answer_no" class="ml-2">Không</label>
                                                    </span>
                                                </div>
                                            </template>
                                        </Column>
                                    </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Panel>
                </div>
            </div>
        </div>
        <Dialog
            header="Cảnh báo"
            :visible.sync="showWarningDialog"
            :contentStyle="{overflow: 'visible'}"
            :modal="true"
        >
            <div v-if="countBooking != 0">
              {{selTime ? selTime.name : null}} {{booking_date ? booking_date : null}} ở chi nhánh {{selBranch ? selBranch.name : null}} đang có {{countBooking}} lịch hẹn, bạn có muốn lưu chứ?
            </div>
            <div v-if="holiday.length > 0">
              {{selTime ? selTime.name : null}} {{booking_date ? booking_date : null}} ở chi nhánh {{selBranch ? selBranch.name : null}} đang là ngày nghỉ/ngày lễ, bạn có muốn lưu chứ?
            </div>
            <template
            #footer
            >
            <Button label="Lưu" @click="submitData()" icon="pi pi-check" class="p-button-success main-style-button" />
            <Button
                label="Hủy"
                @click="showWarningDialog = false"
                icon="pi pi-times"
                class="p-button-warning callback-btn"
            />
            </template>
        </Dialog>
    </form>
</template>

<script>
import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
import ApiRepository from "@/core/ApiRepository";
// import BaseDatatable from './list'
export default {
  components: {
    // BaseDatatable
  },
  props: {
    mode: String,
    model: Object,
    users: Array,
    triggerSave: Boolean
  },
  data() {
    return {
      code: '',
      formMode: this.mode,
      trigger_save: this.triggerSave,
      email: '',
      first_name: '',
      last_name: '',
      service_name: '',
      type_service_name: '',
      full_birthday: null,
      gender: null,
      go_with_name: '',
      go_with: false,
      time_name: '',
      date: '',
      branch_name: '',
      phone: '',
      // email: '',
      date_booking: '',
      uid: '',
      uids: {},
      images: [],
      models: {},
      deleteId: null,
      deleteName: null,
      showWarningDialog: false,
      pagination: true, // true cho phép phân trang
      paginationRows: 10, // số dòng trên 1 trang,
      listData: [],
      // dataModel: null,
      componentKey: 0,
      listCategory: [],
      booking_date: moment.tz(this.date_booking, this.time_zone_local).format("DD/MM/yyyy"),
      filters: {},
      time_zone_local : moment.tz.guess(true),
      DateRange: (new Date().getFullYear() - 100)+":"+(new Date().getFullYear() + 100),
      listBranch: [],
      listBranchAll: [],
      selBranch: [],
      selTime: [],
      listTime: [],
      listTimeAll: [],
      selService: [],
      listService: [],
      listServiceAll: [],
      selTypeService: [],
      listTypeService: [],
      listTypeServiceAll: [],
      type_service_id: null,
      listQuestion: [],
      listQuestionAll: [],
      listanswer: [],
      listUser: [],
      listUserAll: [],
      selUser: [],
      status: 1,
      selStatus: {},
      setGender: null,
      listGender: [{value:0,label:"Chưa chọn"},{value:1,label:"Nam"},{value:2,label:"Nữ"},{value:3,label:"Khác"}],
      listStatus: [{value:1,label:"Đã đặt"},{value:2,label:"Đã xác nhận"},{value:3, label:"Hủy hẹn"},{value:4,label:"Hoàn thành"}],
      reexamine: false,
      DateRangeBirthday: (new Date().getFullYear() - 100)+":"+new Date().getFullYear(),
      patient_code: '',
      is_reexamine: false,
      countBooking: 0,
      holiday: [],
      status_old: 1,
      listStaff: [],
      listStaffAll: [],
      selStaff: [],
    }
  },
  watch: {
    'selTypeService': async function() {
        if (this.selTypeService.id != this.type_service_id) {
            this.selService = [];
        }
        this.type_service_id = this.selTypeService.id;
        await this.getListQuestion();
        await this.getListService();
    },
    'triggerSave'() {
        if (this.triggerSave) {
          this.saveData();
      }
    },
    'selUser'() {
        this.email = this.selUser.email;
        this.full_birthday = this.full_birthday  != null ? this.full_birthday : new Date(this.selUser.full_birthday);
        this.first_name = this.first_name != '' ? this.first_name : this.selUser.first_name;
        this.last_name = this.last_name != '' ? this.last_name : this.selUser.last_name;
        this.phone = this.phone != '' ? this.phone : this.selUser.phone;
        this.prefix_phone = this.prefix_phone != '' ? this.prefix_phone : (this.selUser.prefix_phone ? this.selUser.prefix_phone : '+84');
        this.gender = this.gender != null ? this.gender : this.selUser.gender;
        this.patient_code = this.patient_code != '' ? this.patient_code : this.selUser.patient_code;
        if (this.gender == 1){
            this.setGender = {value:1,label:"Nam"}
        } else if(this.gender == 2){
            this.setGender = {value:2,label:"Nữ"}
        } else if(this.gender == 3){
            this.setGender = {value:3,label:"Khác"}
        } else {
            this.setGender = {value:0,label:"Chưa chọn"}
        }
    },
    'date_booking'() {
        this.booking_date = moment.tz(this.date_booking, this.time_zone_local).format("DD/MM/yyyy");
    }
  },
  async mounted () {
    // this.dataModel = DataServices.getModel('users');
    this.reexamine = this.$route.query.reexamine;
    if (this.$route.params.id !== undefined && this.$route.params.id !== null) {
      await this.getData(this.$route.params.id);
    }
  },
  async created() {
    await this.getListBranch();
    await this.getListTime();
    await this.getListTypeService();
    await this.getListUser();
    await this.getListStaff();
  },
  methods: {
    getField(col) {
      return col.key;
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getHeaderStyleView() {
      var style = "display : none";
      return style;
    },
    getDataRows(col, row) {
      var value = '';
      if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
        var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
        value = result;
      }
      return value
    },
    clickEdit() {
      this.$router.push({ path: '/admin/list-booking/edit/' + this.models.id, params: {id: this.models.id}});
    },
    backToList() {
      this.$router.push({ path: '/admin/list-booking' });
    },
    clickDelete() {
      this.deleteId = this.models.id
      this.deleteName = this.models.fullname
      this.showDeleteDialog = true
    },
    
    async getListQuestion() {
        var where_key = {
            question: {
                deleted : {_eq : false},
                active: {_eq: true}
            },
            type_service_id: {_eq: this.selTypeService.id}
        }
        let {data} = await this.$apollo.query({
            query: DataServices.getList('question_service', {"fields" : `id question {id name}`}),
            variables: {
            where_key: where_key,
            orderBy:[{question: { ordinal : "asc" }}]
            },
            fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_question = [];
        // console.log("list===",list);
        console.log(this.listCategory)
        for (let i = 0; i < list.length; i++) {
            const e = list[i];
            var answer = null;
            if (!this.reexamine) {
                for (let k= 0; k < this.listCategory.length; k++) {
                    if (this.listCategory[k].question_id == e.question.id) {
                        answer = this.listCategory[k].answer;
                    }
                }
            }
            list_question.push({id : e.question.id, answer: answer, name :e.question.name});
        }
        this.listQuestion = list_question;
        this.listQuestionAll = list_question;
    },
    async getListStaff(){

      var where_key = {
        deleted : {_eq : false},
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('staff',{"fields" : `id name`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_user = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_user.push({id : e.id, name :e.name});
      }
      this.listStaff = list_user;
      this.listStaffAll = list_user;
    },
    async fetchOptionsStaff(search, loading){
      console.log(loading);
      console.log('search',search);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          _or: {
            first_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
            last_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('staff',{"fields" : `id name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_user = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_user.push({id : e.id, name :e.name});
        }
        this.listStaff = list_user;
      }else{
        // cosnole.log("dasdasd")
        this.listStaff = this.listStaffAll
      }
    },
    async getListUser(){

      var where_key = {
        deleted : {_eq : false},
        active: {_eq: true},
        type: {_eq: 'user'}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('users',{"fields" : `id first_name last_name email gender phone prefix_phone full_birthday patient_code`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_user = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_user.push({id : e.id, name :e.first_name + ' ' + e.last_name, first_name :e.first_name, last_name: e.last_name, email: e.email, gender: e.gender, phone: e.phone, prefix_phone: e.prefix_phone, full_birthday: e.full_birthday, patient_code: e.patient_code});
      }
      this.listUser = list_user;
      this.listUserAll = list_user;
    },
    async fetchOptionsUser(search, loading){
      console.log(loading);
      console.log('search',search);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          active: {_eq: true},
          type: {_eq: 'user'},
          _or: {
            first_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
            last_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          }
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('users',{"fields" : `id first_name last_name email gender phone prefix_phone full_birthday patient_code`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_user = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_user.push({id : e.id, name :e.first_name + ' ' + e.last_name, first_name :e.first_name, last_name: e.last_name, email: e.email, gender: e.gender, phone: e.phone, prefix_phone: e.prefix_phone, full_birthday: e.full_birthday, patient_code: e.patient_code});
        }
        this.listUser = list_user;
      }else{
        // cosnole.log("dasdasd")
        this.listUser = this.listUserAll
      }
    },
    async getListTypeService(){

      var where_key = {
        deleted : {_eq : false},
        type: {_neq: "contact"}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('type_service',{"fields" : `id name`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_type_service = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_type_service.push({id : e.id, name :e.name});
      }
      this.listTypeService = list_type_service;
      this.listTypeServiceAll = list_type_service;
    },
    async fetchOptionsTypeService(search, loading){
      console.log(loading);
      console.log('search',search);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          type: {_neq: "contact"},
          plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('type_service',{"fields" : `id name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_type_service = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_type_service.push({id : e.id, name :e.name});
        }
        this.listTypeService = list_type_service;
      }else{
        // cosnole.log("dasdasd")
        this.listTypeService = this.listTypeServiceAll
      }
    },
    async getListService(){
      var where_key = {
        deleted : {_eq : false},
        parent_id: {_is_null: true},
        type_service_id: {_eq: this.selTypeService.id}
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('service',{"fields" : `id name`}),
        variables: {
          where_key: where_key,
          orderBy:[{ordinal: 'asc'}, {created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_service = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_service.push({id : e.id, name :e.name});
      }
      this.listService = list_service;
      this.listServiceAll = list_service;
    },
    async fetchOptionsService(search, loading){
      console.log(loading);
      console.log('search',search);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          parent_id: {_is_null: true},
          type_service_id: {_eq : this.selTypeService.id},
          plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('service',{"fields" : `id name`}),
          variables: {
            where_key: where_key,
            orderBy:[{ordinal: 'asc'}, {created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_service = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_service.push({id : e.id, name :e.name});
        }
        this.listService = list_service;
      }else{
        // cosnole.log("dasdasd")
        this.listService = this.listServiceAll
      }
    },
    async getListTime(){

      var where_key = {
        deleted : {_eq : false},
        active : {_eq : true},
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('time',{"fields" : `id name`}),
        variables: {
          where_key: where_key,
          orderBy:[{ordinal: 'asc'}, {created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_time = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_time.push({id : e.id, name :e.name});
      }
      this.listTime = list_time;
      this.listTimeAll = list_time;
    },
    async fetchOptionsTime(search, loading){
      console.log(loading);
      console.log('search',search);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          active : {_eq : true},
          name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('time',{"fields" : `id name`}),
          variables: {
            where_key: where_key,
            orderBy:[{ordinal: 'asc'}, {created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_time = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_time.push({id : e.id, name :e.name});
        }
        this.listTime = list_time;
      }else{
        // cosnole.log("dasdasd")
        this.listTime = this.listTimeAll
      }
    },
    async getListBranch(){

      var where_key = {
        deleted : {_eq : false},
        active : {_eq : true},
      }
      let {data} = await this.$apollo.query({
        query: DataServices.getList('branch',{"fields" : `id name`}),
        variables: {
          where_key: where_key,
          orderBy:[{created_at : 'desc'}]
        },
        fetchPolicy: "network-only"
      })
      var list = data[Object.keys(data)];
      var list_branch = [];
      // console.log("list===",list);
      for (let i = 0; i < list.length; i++) {
        const e = list[i];
        list_branch.push({id : e.id, name :e.name});
      }
      this.listBranch = list_branch;
      this.listBranchAll = list_branch;
    },
    async fetchOptionsBranch(search, loading){
      console.log(loading);
      console.log('search',search);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          active : {_eq : true},
          plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('branch',{"fields" : `id name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_branch = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_branch.push({id : e.id, name :e.name});
        }
        this.listBranch = list_branch;
      }else{
        // cosnole.log("dasdasd")
        this.listBranch = this.listBranchAll
      }
    },
    // async IsEmail(email){
    //   const emailRegexp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    //   return emailRegexp.test(email)
    // },
    async validateData() {
        this.validationErrors = {};
        
        let uid_add = document.getElementById("user_id");
        if (this.selUser == undefined || this.selUser == null || this.selUser == '' || this.selUser.length <= 0){
            uid_add.setCustomValidity("Khách hàng không được để trống!");
        }else {
            uid_add.setCustomValidity("");
        }
        
        let first_name_add = document.getElementById("first_name");
        if (this.first_name == undefined || this.first_name == null || this.first_name == ''){
            first_name_add.setCustomValidity("Họ không được để trống!");
        }else {
            first_name_add.setCustomValidity("");
        }
        let phone_add = document.getElementById("phone");
        if (this.phone == undefined || this.phone == null || this.phone == ''){
            phone_add.setCustomValidity("Số điện thoại không được để trống!");
        }else {
            if (this.phone.length != 10 || !this.$commonFuction.isNumber(this.phone)) {
              phone_add.setCustomValidity("Số điện thoại không được đúng định dạng!");
            } else {
                phone_add.setCustomValidity("");
            }
        }
        
        let last_name_add = document.getElementById("last_name");
        if (this.last_name == undefined || this.last_name == null || this.last_name == ''){
            last_name_add.setCustomValidity("Tên không được để trống!");
        }else {
            last_name_add.setCustomValidity("");
        }
        
        let gender_add = document.getElementById("gender");
        if (this.setGender == undefined || this.setGender == null || this.setGender == '' || this.setGender.length < 0 || this.setGender.value == 0){
            gender_add.setCustomValidity("Giới tính không được để trống!");
        }else {
            gender_add.setCustomValidity("");
        }
        
        let full_birthday_add = document.getElementById("full_birthday");
        if (this.full_birthday == undefined || this.full_birthday == null || this.full_birthday == ''){
            full_birthday_add.setCustomValidity("Ngày sinh không được để trống!");
        }else {
            full_birthday_add.setCustomValidity("");
        }
        
        let email_add = document.getElementById("email");
        if (this.email == undefined || this.email == null || this.email == ''){
            email_add.setCustomValidity("Email không được để trống!");
        }else {
          // console.log("this.IsEmail(this.email)",this.IsEmail(this.email))
          if(await this.$commonFuction.IsEmail(this.email)){
              email_add.setCustomValidity("");
          }else{
            email_add.setCustomValidity("Không đúng định dạng!");
          }
        }

        let date_booking_add = document.getElementById("date_booking");
        if (this.date_booking == undefined || this.date_booking == null || this.date_booking == ''){
            date_booking_add.setCustomValidity("Ngày hẹn không được để trống!");
        }else {
            date_booking_add.setCustomValidity("");
        }
        
        let type_service_add = document.getElementById("typeservice_id");
        if (this.selTypeService == undefined || this.selTypeService == null || this.selTypeService == '' || this.selTypeService.length <= 0){
            type_service_add.setCustomValidity("Loại dịch vụ không được để trống!");
        }else {
            type_service_add.setCustomValidity("");
        }

        let services_add = document.getElementById("service_id");
        if (this.selService == undefined || this.selService == null || this.selService == '' || this.selService.length <= 0){
            services_add.setCustomValidity("Chọn ít nhất 1 dịch vụ!");
        }else {
            services_add.setCustomValidity("");
        }
        
        let time__add = document.getElementById("time_id");
        if (this.selTime == undefined || this.selTime == null || this.selTime == '' || this.selTime.length <= 0){
            time__add.setCustomValidity("Giờ hẹn không được để trống!");
        }else {
            time__add.setCustomValidity("");
        }
        
        let branch_add = document.getElementById("branch_id");
        if (this.selBranch == undefined || this.selBranch == null || this.selBranch == '' || this.selBranch.length <= 0){
            branch_add.setCustomValidity("Chi nhánh không được để trống!");
        }else {
            branch_add.setCustomValidity("");
        }
        
        let status_add = document.getElementById("status");
        if (this.status == undefined || this.status == null || this.status == '' || this.status.length <= 0){
            status_add.setCustomValidity("Trạng thái không được để trống!");
        }else {
            status_add.setCustomValidity("");
        }

        var inpObj = document.getElementById("frm_action");
        if (!inpObj.checkValidity()){
            console.log('test=====================');
            inpObj.reportValidity();
            return true;
        }
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      this.$emit('update:triggerSave', false);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      
      if(Object.entries(this.validationErrors).length === 0){
        // var tmp_expiration_date = null;
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        var checkParams = {
            id: !this.is_reexamine ? this.$route.params.id : null,
            time_id: this.selTime.id,
            branch_id: this.selBranch.id,
            date_booking: moment.tz(this.date_booking, this.time_zone_local).valueOf()
        }
        ApiRepository.post(this.$constants.API.CHECK_BOOKING, checkParams, {headers}).then(async (res) => {
            console.log('=========> submit booking: ', res)
            if (res.data.data.time.length > 0 || res.data.data.holiday.length > 0) {
                if (res.data.data.time.length > 0) {
                  this.countBooking = res.data.data.time[0].timeCount ?? "> 3";
                }
                if (res.data.data.holiday.length > 0) {
                  this.holiday = res.data.data.holiday;
                }
                this.$commonFuction.is_loading(false);
                this.showWarningDialog = true;
            } else {
                this.submitData();
            }
        }).catch(async error => {
            console.log('error', error);
            this.$toast.add({
                severity: 'error',
                summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
                detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
                life: 3000
            });
            this.$commonFuction.is_loading(false);
        })
      }else{
        this.$commonFuction.is_loading(false);
      }
    },
    async submitData() {
        this.$commonFuction.is_loading(true);
        this.showWarningDialog = false;
        var params = {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            gender: this.setGender.value,
            full_birthday: this.full_birthday,
            patient_code: this.patient_code,
            prefix_phone: this.prefix_phone,
            phone: this.phone,
            go_with: this.go_with,
            date_booking: new Date(this.date_booking),
            questions: this.listQuestion,
            services: this.selService,
            time: this.selTime,
            branch_id: this.selBranch.id,
            type_service_id: this.selTypeService.id,
            uid: this.selUser.id,
            status: this.selStatus.value,
            is_reexamine: this.is_reexamine,
            staff_id: this.selStaff ? this.selStaff.id: null
        }
        console.log("params",params)
        this.$emit('save', params);
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('booking', {"fields" : "id first_name last_name phone user {id first_name last_name email} prefix_phone patient_code gender full_birthday go_with code email date_booking status booking_lines {service {id name}} time {id name} branch {id name} type_service {id name} booking_questions {question_title question_id answer} staff {id name}"}),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
        this.status = this.models.status;
        this.status_old = this.models.status;
        this.full_birthday = new Date(this.models.full_birthday);
        this.gender = this.models.gender;
        this.code = this.models.code;
        this.first_name = this.models.first_name;
        this.last_name = this.models.last_name;
        this.patient_code = this.models.patient_code;
        this.prefix_phone = this.models.prefix_phone;
        if (this.models.user !== undefined && this.models.user !== null){
            this.selUser = {id: this.models.user.id, name: this.models.user.first_name + ' ' + this.models.user.last_name, email: this.models.user.email};
            this.email = this.models.user.email;
        }
        this.phone = this.models.phone;
        this.go_with = this.models.go_with;
        if (!this.reexamine) {
            this.date = moment.tz(this.models.date_booking, this.time_zone_local).format("DD/MM/yyyy");
            this.date_booking = new Date(this.models.date_booking);
        } else {
            this.is_reexamine = true;
        }
        if (this.gender == 1){
            this.setGender = {value:1,label:"Nam"}
        } else if(this.gender == 2){
            this.setGender = {value:2,label:"Nữ"}
        } else if(this.gender == 3){
            this.setGender = {value:3,label:"Khác"}
        } else {
            this.setGender = {value:0,label:"Chưa chọn"}
        }
        if (this.formMode == 'add') {
            this.selStatus = {value:1,label:"Đã đặt"}
        } else if (this.formMode == 'edit') {
            if (this.status == 1){
                this.selStatus = {value:1,label:"Đã đặt"}
            } else if(this.status == 2){
                this.selStatus = {value:2,label:"Đã xác nhận"}
            } else if(this.status == 3){
                this.selStatus = {value:3, label:"Hủy hẹn"}
            } else if(this.status == 4){
                this.selStatus = {value:4,label:"Hoàn thành"}
            }
        }
        
        if(this.models.staff !== undefined && this.models.staff !== null){
          this.selStaff = {id: this.models.staff.id, name: this.models.staff.name};
        }
        
        if(this.models.booking_questions !== undefined && this.models.booking_questions !== null){
          this.listCategory = this.models.booking_questions;
        }
        var service_name_arr = [];
        if(this.models.time !== undefined && this.models.time !== null){
          this.time_name = this.models.time.name;
          this.selTime = {id: this.models.time.id, name: this.models.time.name};
        }
        if(this.models.branch !== undefined && this.models.branch !== null){
          this.branch_name = this.models.branch.name;
          this.selBranch = {id: this.models.branch.id, name: this.models.branch.name};
        }
        if(this.models.type_service !== undefined && this.models.type_service !== null){
          this.type_service_name = this.models.type_service.name;
          this.type_service_id = this.models.type_service.id;
          this.selTypeService = {id: this.models.type_service.id, name: this.models.type_service.name};
        }

        this.go_with_name = this.models.go_with == true ? 'Có' : 'Không';
        if(this.models.booking_lines !== undefined && this.models.booking_lines !== null){
          for (var k = 0; k < this.models.booking_lines.length; k++) {
            if (this.models.booking_lines[k].service !== undefined && this.models.booking_lines[k].service.name) {
              service_name_arr.push(this.models.booking_lines[k].service.name);
              this.selService.push({id: this.models.booking_lines[k].service.id, name: this.models.booking_lines[k].service.name});
            }
          }
        }
        this.service_name = service_name_arr.join(', ');
        console.log(service_name_arr);
        // console.log("dsada",this.models.users_category)

        this.componentKey++;
      }
    }
  },
  apollo: {
  },
  
}
</script>
<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.view-action{
  .toolbar-top{background: #fff; padding: 0; border: 0 none;margin-bottom: 16px; padding-bottom: 10px; border-bottom: 1px solid #EEEEEE;
    .manager-title{border: 0 none; padding: 0; margin: 0;}
    .p-toolbar-group-right {
      .p-button{margin-right: 8px; width: auto;
        &:last-child{margin-right: 0;}
      }
    }
  }
  .p-tabview{
    .p-tabview-panels{ padding: 0;}
    .p-tabview-nav{
      li{margin-right: 8px;
        .p-tabview-nav-link{border: 1px solid #B4B4B4; border-radius: 5px 5px 0px 0px !important; background: #eee; font-size: 15px;
          &:hover{text-decoration: none;}
        }
        &.p-highlight{
          .p-tabview-nav-link{background: #00A8FF !important;border: 1px solid #B4B4B4; border-radius: 5px;}
        }
        &:hover{
          .p-tabview-nav-link{background: #00A8FF !important; color: #fff !important;}
        }
      }
    }
  }
  .p-formgrid { margin: 0;
    .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
    .p-card-field{ padding: 24px;
      .p-field{
        .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
        .p-inputtext p-component p-filled{width:500px !important; border-radius: 5px;
          &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px; color: #707070; height: 40px;}
          &.rows-4{height: 80px;}
          .file-select{margin: 0;
            input[type="file"]{display: none;}
            .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
          }
        }
        .box-images{ margin-top: 10px; position: relative;
          .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
            &.p-col-5{width: 18%;;}
            img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
            .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
              img{vertical-align: top;}
            }
          }
        }
      }
    }
  }
  .p-rates{
    .top-rates{ padding: 16px;color: #000;border-bottom: 1px solid #B4B4B4; 
      label{margin: 0;}
      span{font-weight: 600; padding-left: 100px;}
    }
    .list-rates{padding: 16px;}
  }
  .table-view >.p-datatable .p-datatable-tbody > tr > td {
    color: #707070 !important;
  }
  .p-inputtext {
    min-height: 40px;
  }
  .p-formgrid .p-card-field .p-field .p-col-width {
    min-height: 40px;
    width: 100% !important;
  }
  .vs__dropdown-option.vs__dropdown-option--selected,
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: #142053 !important;
    color: #ffffff;
  }
  .span-cus {
    color : red;
  }
}
</style>